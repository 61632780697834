<template>
  <page-layout>
    <div class="share-result">
      <spinner
        v-if="isLoading"
        :title="$t('resultDescription.analysisProcessing')"
        class="layout-spinner"
        size="50"
      />
      <empty-result
        v-else-if="!layout"
        :result-info="emptyMsg"
      />
      <component
        v-else
        :is="layout"
        :data-result-id="currentResultId"
        :data-frame-id="currentQuestionDataFrameId"
        :result-info="resultInfo"
        :restrictions="restrictInfo"
        :segmentation-payload="segmentationPayload"
      />
    </div>
  </page-layout>
</template>
<script>
import PageLayout from '@/components/layout/PageLayout'
import { defineComponent } from '@vue/composition-api'
import { useAskingModuleContext } from '@/modules/shared/asking'
import { registerPinBoardModuleContext, PIN_BOARD_MODULE_NAMESPACE } from '@/modules/pinBoard'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'SingleResult',
  components: {
    PageLayout
  },
  setup () {
    registerPinBoardModuleContext()
    const { getComponentList } = useAskingModuleContext()
    return {
      getComponentList
    }
  },
  data () {
    return {
      isLoading: true,
      layout: null,
      resultInfo: null,
      restrictInfo: [],
      segmentationPayload: null,
      timeoutFunction: null,
      currentResultId: this.$route.params.id,
      currentQuestionDataFrameId: null
    }
  },
  computed: {
    emptyMsg () {
      return {
        title: this.$t('editing.emptyResult'),
        description: this.$t('editing.emptyPinboard')
      }
    }
  },
  mounted () {
    this.setCurrentAskingModuleBelongsTo(PIN_BOARD_MODULE_NAMESPACE)
    this.fetchData(this.currentResultId)
  },
  methods: {
    ...mapActions('dataSource', ['setCurrentAskingModuleBelongsTo']),
    fetchData (resultId) {
      this.getComponentList({ resultId, displayInsight: false })
        .then(componentResponse => {
          switch (componentResponse.status) {
            case 'Process':
            case 'Ready':
              this.timeoutFunction = window.setTimeout(() => {
                this.fetchData(resultId)
              }, this.totalSec)

              this.totalSec += this.periodSec
              this.periodSec = this.totalSec
              break
            case 'Complete':
              this.totalSec = 50
              this.periodSec = 200
              this.resultInfo = componentResponse.componentIds
              this.restrictInfo = componentResponse.restrictions
              this.layout = this.getLayout(componentResponse.layout)
              this.segmentationPayload = componentResponse.segmentationPayload
              this.currentQuestionDataFrameId = componentResponse.transcript.dataFrame.dataFrameId
              this.isLoading = false
              break
            case 'Disable':
            case 'Delete':
            case 'Warn':
            case 'Fail':
              this.layout = 'EmptyResult'
              this.isLoading = false
              break
          }
        })
        .catch(() => {
          this.isLoading = false
        })
    }
  }
})
</script>
<style lang="scss" scoped>
.share-result {
  width: 90%;
  margin: 0 auto;
}
</style>
